@include desktop {
	.mm-menu.mm-offcanvas.mm-current {
		display: none!important;
	}
	html.mm-right.mm-opening .mm-slideout {
		transform: none;
	}
}

@include sm {
	.main-menu {
		.navbar-collapse > ul > li > a  {
			padding: 14px 14px 14px;
		}
		.sub-menu {
			max-width: 250px;
			a {
				white-space: normal;
			}
		}
	}

	.member {
		.panel-heading {
			padding: 15px;
			font-size: 20px;

		}
		ul {
			li + li {
				margin: 10px 0 0;
			}
			li a {
				font-size: 16px;
			}
		}
		.panel-body {
			padding: 15px;
		}
	}
	.post-lastest .article-item h3 {
		font-size: 20px;
	}
	.sticker-article {
		padding: 10px 15px 0;
		.slick-next {
			right: 0;
		}
		.slick-prev {
			left: 0;
		}
	}
	.widget-bottom {
		.col-md-3 {
			margin-bottom: 15px;
		}
	}
	footer.footer .menu-bottom ul li,.contact-info {
		font-size: 16px;
	}
}
@include xs {

	//fixed positon on mobile
	#header {
		position: fixed;
		z-index: 999;
		top: 0;
		left: 0;
		.mm-opened & {
			position: relative;
		}
	}
	body {
		padding-top: 105px;
		.mm-opened & {
			padding-top: 0;
		}
	}
	.mm-opened {
		.navbar-toggle {
			height: 37px;
			margin-right: 20px;
			.icon-bar {
				height: 23px;
				width: 2px;
				position: absolute;
				margin: 0;
			}
			.icon-bar:nth-child(1) {
				@include rotate(45deg)
			}
			.icon-bar:nth-child(2) {
				background: none;
			}
			.icon-bar:nth-child(3) {
				@include rotate(-45deg)

			}
		}
	} 

	//menu mobile

	#menu-mobile-parent {
		// level 1 only
		.caret {
			display: none;
		}
		.pull-right {
			float: none!important;
		}
		.logged {
			.dropdown-menu {
				position: static;
				float: none;
			}
		}
		& > ul {
			margin-left: 0;
			& > li {
				margin: 1px 0;
				border: none;
				& > a {
					border-radius: 8px 0 0 8px;
					background: $branch-color;
					border: none;
					border-right: 9px solid #859CC2;	

				}
					&:nth-child(1) > a{
						border-color: transparent;
					}
					&:nth-child(2) > a{
						border-color: #859CC2;
					}
					&:nth-child(3) > a {
						border-color: #9cb7b4;
					}
					&:nth-child(4) > a{
						border-color: #D3C2A0;
					}
					&:nth-child(5) > a {
						border-color: #739478; 
					}
					//#739478
			}


			//all level
			
			.parent {
				& > a {

				}
				.mm-panel {
					background: white;
					position: relative;
					border-radius: 0 0 0 8px;
					padding:18px 0 10px 0;
					margin: 0 0 5px 0;
					top: -8px;
					.active {
						a {
							background: none;
							font-size: 18px;
							font-weight: 700;
						}
					}
					a {
						color: $branch-color;
					}
					.sub-menu {
						position: static;
						margin: 0;
						float: none;
						background: none;
						border-radius: 0;
						box-shadow: none;
						border: none;
						padding: 0;
						.sub-menu {
							padding-left: 20px;
						}
					}
				}
			}

			// style for my account section
			
			.logged {
				.fa {
					position: absolute;
					right: 5px;
					top: 50%;
					@include translateY(-50%);
					font-size: 26px;
				}
				&.mm-opened {
					.fa:before {
						content:"\f106";
					}
				}
			}
			li {
				.mm-next {
					width: 100%;
					position: absolute;
					z-index: 3;
					background: none;

					& + a , & + span {
						margin: 0;
					}
					&:after,&:before {
						display: none;
					}
				}
				&:after,&:before {
					border: none!important;
				}
				a {
					color: white;
					font-size: 16px;
					&.dropdown-toggle {
						position: relative;
						z-index:2;
					}
					&:hover {
						opacity: 1;
					}
				}
			}
		}
	}

	//form search
	.navbar-form {
		margin: 0;
		box-shadow: none;
		float: none!important;
		padding:40px 20px 20px; 
		width: auto;
		.form-control {
			border-color: white;
			@include placeholder(white);
			color: white;
		}
		.form-group .btn{
			right: 0;
			 i {
				color: white;
			}
		}
	}


	.header-top {
		padding: 10px 0;
	}

	.navbar-toggle {
		margin-right: 10px;
		.icon-bar {
			height: 3px;
			border-radius: 3px;
		}
	}

	#header .navbar-brand img {
		max-width: 93px;
	}
	.card-box {
		margin: 10px auto;
		//max-width: 480px;
	}
	.main-menu {
		padding: 31px 0 0 0;
	}
	.hero-slider .content{
		top: 50%;
		@include translateY(-50%);
		 .container {
			padding-left: 15px;
			h1 {
				font-size: 36px;
			}
			p,.btn  {
				font-size: 16px;
			}
			.btn {
				padding: 10px 25px;
			}

		}
	}
	.widget-bottom {
		padding: 0;
	}
	
	//member section
	.member{
		padding-bottom: 15px;
		.panel {
			margin: 0;
			&.active {
				.panel-heading {
					border-radius: 0!important;
					&:after {
						content: "\f077";
					}
				}
			}
			.panel-heading {
				font-size: 20px;
				position: relative;
				cursor:poiner;
				&:after {
					margin-top: -5px;
					content:"\f078";
					font-family: 'FontAwesome';
			  		color: white;
			  		font-size: 14px;
			  		display: block;
			  		position: absolute;
			  		top: 50%;
			  		@include translateY(-50%);
			  		right: 20px;
			  		width: 5px;
			  		height: 11px;
				}
			}
			&.active {
				.panel-body {
					display: block;
				}
			}
		}
		.col-sm-4{
			.panel-heading {
				border-radius: 0;
			}
			&:first-child  .panel-heading{
				border-radius: 8px 8px 0 0;
			}
			&:last-child  .panel-heading{
				border-radius: 0 0 8px 8px;
			}
		}
		.panel-body {
			display: none;
			border-radius: 0;
		}

	}
	.sticker-article {
		background: none;
		padding: 0;
		margin: 15px 0 0 ;
		.slick-slide {
			padding: 0;
		}
		.slick-next {
			right: -15px;
		}
		.slick-prev {
			left: -15px;
		}
		.slick-next,.slick-prev {
			width: 25px;
			height: 44px;
			top: 50%;
			background: white;
			z-index: 9;
			box-shadow: -1px 0 3px rgba(0,0,0,0.2);
		}
	}
	// post-lastest
	.post-lastest {
		padding: 15px 0;
		.article-item {
			.article-item-content {
				padding: 20px 18px;
			}
			h3 {
				font-size: 22px;
			}
			img {
				width: 100%;
			}
		}
	}
	//footer
	footer.footer {
		padding: 10px 0;
		.menu-bottom {
			//margin: 0 -15px;
			border: none;
			ul li {
				display: block;
				padding: 10px;
				width: 100%;
				border: none;
				border-bottom: 1px solid white!important;
			}
		}
		.contact-info {
			font-size: 16px;
			p {
				margin: 20px;
				br {
					display: block;
				}
			}
		}
		.social {
			margin: 38px 0;
			li a {
				width: 48px;
				height: 48px;
				i {
					font-size: 25px;
				}
			}
		}
	}
}