$screen-md: 1025px !default;
// Font family
@font-face {
    font-family: 'Georgia';
    src: url('../fonts/Georgia.eot');
    src: url('../fonts/Georgia.woff') format('woff'), url('../fonts/Georgia.ttf') format('truetype'), url('../fonts/Georgia.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

// Color
$white: #fff;
$black: #000;
$dark-grey: #444444;
$dark-greyer: #555555;
$light-grey: #666;
$lighter-grey: #e5e5e5;
$white-smoke: #999999;
$white-light-smoke: #f8f8f8;
$dark-blue: #001a34;
$light-blue: #0079a0;
$branch-color: #233588;
$orange: #ff6300;
$black-red: #010000;
$vhFull: 100vh;
$minVhFull: 900px;
$minVhFullMobile: 540px;
// Font-faceregular
$light: 300;
$regular: 400;
$semi-bold: 600;
$bold: 700;
// Global Component
$heightHeader: 70px;
$heightSubHeader: 50px;
// variables for mobile
$heightHeaderMobile: 50px;
$heightMenuItemsMobile: 45px;
//font georgia
$text-color: $light-grey;

