
// MAIN MENU

.main-menu {
	background: $branch-color;
	padding: 27px 0 0;
	min-height: 30px;
	border-bottom:1px solid white;
	letter-spacing: 0;
	.navbar-collapse {
		padding: 0;

		.caret {
			display: none;
		}
		// FIRST LI ELEMENTS
		& > ul > li {
			
			& + li {
				margin-left: 2px;
			} 

		
			// FIRST  A ELEMENTS
			& > a {
				border-top: 6px solid #859CC2;
				border-radius: 4px 4px 0 0;
				padding: 16px 43px 12px 43px;
				background: white;	
				font-size: 18px;

				color: $light-grey;
				&:focus,&:active,&:hover {
					background: #859CC2;
					color: white;
					opacity:1;
				}

			}

			// STYLE FOR 2,3,4,5 ELEMENTS
			&:nth-child(2) > a{
				border-color: #9cb7b4;
				&:focus,&:active,&:hover {
					background:  #9cb7b4;	
				}
				
			}
			&:nth-child(3) > a {
				border-color: #D3C2A0;
				&:focus,&:active,&:hover {
					background:  #D3C2A0;
					
				}
			}
			&:nth-child(4) > a{
				border-color: #C1957B;
				&:focus,&:active,&:hover {
					background:  #C1957B;			
				}
			}
			&:nth-child(5) > a {
				border-color: #739478; 
				&:focus,&:active,&:hover {
					background:  #739478;
				}
			}
			
			// ACTIVE , OPEN STATUS

			 &.active,&.open {
				& > a {
					background: white!important;
					&:focus,&:active,&:hover {
						background: white!important;
						border-color: #859CC2; 
						color: $text-color;
					} 
					&:before {
						 background: white none repeat scroll 0 0;
					    bottom: -1px;
					    content: "";
					    display: block;
					    height: 3px;
					    left: 0;
					    position: absolute;
					    width: 100%;
					    z-index: 1001;
					}
					&:after {
						background: #859CC2;
					    border-radius: 4px 4px 0 0;
					    content: "";
					    display: block;
					    height: 8px;
					    left: 0;
					    position: absolute;
					    top: -6px;
					    width: 100%;
					}
				}
				
				// STYLE FOR 2,3,4,5 ELEMENTS
				
				&:nth-child(2) > a{
					background:  #9cb7b4;	
				}

				&:nth-child(3) > a {
					background:  #D3C2A0;
				}
				&:nth-child(4) > a{
					background:  #C1957B;	
				}
				&:nth-child(5) > a {
					background:  #739478;
				}


				&:nth-child(2) {
					&.active,&.open  > a{
						&:after {
							background: #9cb7b4;
						}
					}
				}

				&:nth-child(3) {
					&.active,&.open  > a{
						&:after {
							background: #D3C2A0;
						}
					}
				}

				&:nth-child(4) {
					&.active,&.open  > a{
						&:after {
							background: #C1957B;
						}
					}
				}

				&:nth-child(5) {
					&.active,&.open  > a{
						&:after {
							background: #739478;
						}
					}
				}
			}
	
		}		
	}

	// SUB MENU STYLE
	.sub-menu {
		border: none;
		border-top: 1px solid rgba(0, 0, 0, 0.03);
		border-radius: 0 2px 8px 8px;
		box-shadow: 0 5px 5px rgba(0, 0, 0, 0.175);
		.sub-menu {
			border-radius: 0 0 8px 0;
			margin: 0;
		}
		li {
			a {
				color: $branch-color;

				
				padding: 10px 28px;
				font-size: 18px;
				&:hover {
					background: #f5f5f5
				}
			}
			& + li {

					border-top: 1px solid #eee;
				
			}

		}
		li.parent {
			position: relative;
		}
		li:hover {
			ul {
				display: block;
				top: 0;
				left: 100%;
			}
		}
	}
}

// MENU FOR MOBILE ONLY
#menu-mobile:not( .mm-menu ) {
   display: none;
}

