footer.footer {
	background: $branch-color;
	color: white;
	padding: 10px 0 100px 0;
	margin: 12px 0 0;
	text-align: center;
	ul {
		list-style: none;
		padding: 0;
		li a {
			color: white;
		}
	}
	.social {
		margin: 38px 0 64px 0;
		li {
			display: inline-block;
			& + li {
				margin: 0 0 0 12px;
			}
			a {
				display: block;
				width: 66px;
				height: 66px;
				position: relative;
				border-radius: 8px;
				background: white;
				i {
					color: $branch-color;
					font-size: 32px;
					position: absolute;
					top: 50%;
					left: 50%;
					@include transform(-50%,-50%);
				}
			}
		}
	}
	.menu-bottom {
		 border-bottom: 2px solid white;
		ul {
			display: table;
			width: 100%;
			li {
				font-size: 20px;
				font-weight: 400;
				width: 33.3333%;
				&:first-child {
					border: none;
				}
				border-left: 2px solid white;
				display: table-cell;
				padding: 8px;
			}
		}
	}
}

.contact-info {
	font-weight: 300;
	font-size: 18px;
	a {
		color: white;
		&.link {
			text-decoration: underline;
		}
	}
	p {
		margin: 0 0 0 15px;
		br {
			display: none;
		}
	}
}
