#header{
	position: relative;
	z-index:2;
	width: 100%;
	.is-sticky  & {
		.header-top {
			//padding: 0;
			.clearfix {
				//display: none;
			}
		}
		.navbar-brand > img {
			width: 90%;
			
		}
	}
	.navbar-brand {
		padding: 0;
		position: absolute;
		top: 0;
		left: auto;
		img {
			transition: all 0.3s linear;
			width: 100%;
		}
	}
	background: #D8D8D8;
}

.sticky {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 100;
  border-top: 0;
}

.header-top {
	padding: 60px 0 20px 0;
}

.navbar-form{
	margin: 0;
	.form-control {
		background: none;
		border: none;
		border-radius: 0;
		border-bottom: 2px solid $branch-color;
		box-shadow: none;
		outline: none;
		height: 36px;
		font-size: 18px;
		padding: 6px 36px 6px 0;
		color: $branch-color;

		@include placeholder($branch-color);
		//font-weight: 300;

	}
	.form-group {
		position: relative;
		
		.btn {
			border: none;
			background: none;
			padding: 0;
			position: absolute;
			right: 12px;
			top: 50%;
			&:active,&:focus,&:hover,&.active {
				background: none;
				outline: none;
				border: none;
				box-shadow: none;
			}
			@include translateY(-50%);
			i {
				color: $branch-color;
				font-size: 20px;
			}
		}
	}
}


// my account menu

.account {
	margin: 6px 0 0 5px;
	ul {
		padding: 0;
		margin: 0;
		list-style: none;
		li.login a {
			padding: 8px 16px;
			border: 2px solid $branch-color;
			color: $branch-color;
			border-radius: 4px;
		}
		li.logged {
			padding: 16px 20px;
			margin-top: -20px; 
			.fa {
				margin-left: 10px;
				font-size: 28px;
				vertical-align: -2px;
			}
			&.open {
				background: white;
				border-radius: 8px 8px 0 0;

				li a {
					background: none;
					&:hover {
						background: #f5f5f5;
					}
				}
				&:after {
					content:"";
					background: white;
					bottom: 0;
					z-index: 9999;
					height:2px;
					width: 100%;
					position: absolute;
					display: block;
					right: 0;
				}
			}	
			
		}
	}
	
	ul {
		li {
			a {
				color: $branch-color;
				font-size: 18px;

			}

			li {
				a {
					padding: 10px 28px;
				}
				& + li {
					border-top: 1px solid #eee;
				}
			}
		}
	}
	.dropdown-menu {
		padding: 8px 0;
		box-shadow: 0 1px 2px rgba(0,0,0,0.2);
		border-radius: 8px 0 8px 8px;
		border: none;
		.active {
			a {
				font-weight: 700;
				color: $branch-color;
				background: #f5f5f5;
			}
		}
	}
	
}

#menu-mobile:not( .mm-menu ) {
   display: none;
}

