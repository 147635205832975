@media (min-width: $sm-width) {
	.row {
		margin-left: -5px;
		margin-right: -5px;
		
	}
	.container {
		padding-left: 5px;
		padding-right: 5px;
		@media (min-width: 1280px) {
			min-width: 1208px;
		}
	}
	.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12
		 {
			padding-left: 5px;
			padding-right: 5px;
	}	
}

img {
	max-width: 100%;
}
.navbar-default{
	background: none;
	border: none;
	min-height: auto;
	margin-bottom: 0;
}
.panel-default {
	border-radius: 8px;
	box-shadow: none;
	background: none;
	border: none;
	& > .panel-heading {
		background: $branch-color;
		border: none;
		border-bottom: 14px solid #859CC2;
		font-size: 26px;
		color: white;
		font-weight: 500;
		padding: 17px 36px;
		border-radius: 8px 8px 0 0;
	}
	.panel-body {
		padding: 36px;
		border-radius: 0 0 8px 8px;
		background: #D8D8D8;
	}
} 

.btn-default {
	padding: 10px 20px;
	border: 2px solid $branch-color;
	color: $branch-color;
}
.navbar-toggle {
	.icon-bar {
		background: $branch-color;
	}
}



// menu offcanvas

.mm-menu.mm-offcanvas.mm-current {
	display:block!important
}

.mm-menu.mm-offcanvas {
	background: #3D87DD;
	box-shadow: 1px 0 5px rgba(0,0,0,0.2) inset, 1px 0 1px white;
	border-left: 1px solid rgba(225,225,225,0.2);
	.mm-panels {
		background: none;
	}
	#menu-mobile-parent {
		margin-top: 105px;
	}
}

