@import "mixin.scss";
body {
    font-family: 'Helvetica Neue' , helvetica,Arial,sans-serif;
    color: $text-color;
    font-size: 16px;
    letter-spacing: 1px;
}


ul {
    &.no-bullet {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }
}

* {
    outline: none;
}
a{
    @include transition(0.3s);
    color: $branch-color;
}
a:hover,
a:active,
a:focus {
    text-decoration: none;
    opacity: 0.8;
}

// Typo
h1,
.h1 {
    font-size: 50px;
}

h2,
.h2 {
    font-size: 36px;
    font-weight: 500;
}

h3,
.h3 {
    font-size: 20px;
    font-weight: 500;
    letter-spacing: 1px;
}

h4,
.h4 {
    font-size: 18px;
    font-weight: 500;
}

h5,
.h5 {
    font-size: 16px;
    font-weight: 500;
}

h6,
.h6 {font-weight: 500;}


img {
    max-width: 100%;
    width: auto;
    height: auto;
}

input {
    border-radius: 0;
}

p {
    line-height: 1.3;
}
.main p{
    line-height: 1.6;
}

input,
textarea {
    outline: none !important;
    padding: 0 10px;
}
