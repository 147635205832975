.hero-slider {
	color: white;
	
	 .image {
		background-size: cover;
		background-position: center top;
		min-height: 459px;
		@media (min-width: 1600px) {
			min-height: 600px;
		}
	}
	&.slick-vertical {
		.slick-slide {
			position: relative;
			border: none;
		}
	}
	.content {
		position: absolute;
		width: 100%;
		top: 84px;
		left: 0;
		z-index: 9;
		.container {
			padding-left: 143px;
		}
		h1 {
			font-weight: 300;
			margin: 0 0 5px;
		}
		p {
			font-size: 26px;
		    margin: 0 0 18px;
		    line-height: 1.2;
		    font-weight: 300;
		    letter-spacing: 1px;
		}
		.btn {
			font-size: 18px;
			border: 2px solid white;
			color: white;
			background: none;
			padding: 5px 32px;
		}
	}
	.slick-dots {
		left: 20px;
		top: 32%;
		width: auto;
		bottom: auto;
		@include translateY(-50%);
		z-index: 0;
		text-align: left;
		li {
			float: none;
			margin: 0;
			display: block;
			& + li {
				margin-top: 10px;
			}
			&.slick-active {
				button {
					opacity: 1;
				}
			}
			button {
				height: 21px;
				width: 21px;
				opacity: 0.6;
				background: white;
				border-radius: 50%;
				&:before {
					display: none;
				}
			}

		}
	}
}

.member {
	margin-top: -75px;
	.col-sm-4 {
		&:nth-child(3) {
			.panel-default > .panel-heading {
				border-color: #F1F1F1;
			}
		}
		&:nth-child(2) {
			.panel-default > .panel-heading {
				border-color: #DBC296;
			}
			
		}
	}
	.panel-body {
		padding: 34px;
	}
	ul {
		list-style: none;
		padding:0;
		margin: 0;
		li {
			& + li {
				margin-top :34px;
			}
			a {
				font-size: 20px;
				color: $text-color;
				font-weight: 500;
				i {
					color: $branch-color;
					margin-right: 15px;
					font-size: 20px;
				}
			}
		}
	}
}

.sticker-article {
	padding: 10px 58px 0;
	background: white;
	border-radius: 8px;
	.slick-slide {
		padding: 0 4px;
		img {
			//max-width: 322px;
		}
	}
	  .slick-prev, .slick-next {
	  	top: 44%;
	  	&:before {
	  		font-family: 'FontAwesome';
	  		color: $branch-color;
	  		font-size: 22px;
	  	}
	  }
	  .slick-next {
	  	right: 28px;
	  	color: $branch-color;
	  	&:before {
			content:"\f054";

	  	}
	  }
	  .slick-prev {
	  	left: 28px;
	  	&:before {
	  		content:"\f053";
	  	}
	  }
}

//post-lastest
.post-lastest {
	background: rgba(236,236,236,0.7);
	padding: 38px  0;
	.article-item {
		border-radius: 8px;
		background: white;
		h3 {
			font-size: 26px;
			margin: 0 0 10px;
		}
		img {
			border: 8px 8px 0 0;
		}
		p {
			margin: 0 0 14px;
		}
		.btn {
			padding: 5px 20px;
		}
		.article-item-content {
			padding: 25px 35px;
		}
	}
	.col-sm-8 {
		.article-item {
			h3 {
				margin: 0 0 10px;
			}
			p {
				line-height: 1.2;
			}
		}

		//background: white;
		.article-item-content {
			padding: 18px 12px;
			min-height:   170px;
		}
	}
}

.widget-bottom {
	padding: 40px 0;
}

.card-box {
	display: block;
	position: relative;
	border-radius: 8px;
	.img {
		border-radius: 8px;
		min-height: 174px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center top;
	}
	.card-box-content {
		position: absolute;
		padding: 25px 28px;
		left: 0;
		top: 0;
		color: white;
		h3 {
			margin: 0 0 16px;
			font-weight: 400;
			letter-spacing: 1px;
			font-size: 18px;
		}
		p {
			margin: 0;
			line-height: 1.2;

		}
	}
}